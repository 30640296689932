import { Section } from "../../interfaces/interfaces";
export const BioC1000 = {
    id: 1,
    text: "Bio C 1000",
    imageUrl: "bioc.png",
    externalUrl: "https://www.blackmores.com.au/products/bio-c-1000?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Immunity,
    claim: "A boost of vitamin C to reduce the severity and duration of common cold symptoms and support immune system health.",
};
export const Multi50Plus = {
    id: 2,
    text: "Multivitamin for 50+",
    imageUrl: "multi-50plus.png",
    externalUrl: "https://www.blackmores.com.au/products/multivitamin-for-50-plus?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Everyday,
    claim: "A comprehensive blend of 24 nutrients to support people over 50.  ",
};
export const MultiMen = {
    id: 3,
    text: "Multivitamins for men",
    imageUrl: "multi-men.png",
    externalUrl: "https://www.blackmores.com.au/products/multivitamins-for-men",
    applicableSection: Section.Everyday,
    claim: "This convenient one-a-day multivitamin supports testosterone levels and mental function and is designed with a unique sustained release technology that release nutrients to the body gradually throughout the day.",
};
export const MultiWomen = {
    id: 4,
    text: "Multivitamins for Women",
    imageUrl: "multi-women.png",
    externalUrl: "https://www.blackmores.com.au/products/multivitamins-for-women",
    applicableSection: Section.Everyday,
    claim: "This convenient one-a-day multivitamin supports collagen formation and skin health and is designed with a unique sustained release technology that release nutrients to the body gradually throughout the day.",
};
export const SustainedRelease = {
    id: 5,
    text: "Multivitamins + Antioxidants",
    imageUrl: "multi-antiox.png",
    externalUrl: "https://www.blackmores.com.au/products/multivitamins-antioxidants",
    applicableSection: Section.Everyday,
    claim: "A comprehensive multivitamin that supports immune system function and energy production with added antioxidants to help protect cells from free radical damage.",
};
export const WomensEnergyEffervescent = {
    id: 6,
    text: "Women's Energy Effervescent",
    imageUrl: "womens-effervescent.png",
    externalUrl: "https://www.blackmores.com.au/products/womens-energy-effervescent?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Pregnancy,
    claim: "A refreshing way to support energy levels and is suitable during pregnancy and breastfeeding.   ",
};
// Pregnancy
export const PregnancyBreastFeedingGold = {
    id: 7,
    text: "Pregnancy & Breast-Feeding Gold",
    imageUrl: "pregnancy-breastfeeding-gold.png",
    externalUrl: "https://www.blackmores.com.au/products/pregnancy-breast-feeding-gold?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Pregnancy,
    claim: "Provides an ideal dose of 20 important nutrients for mother including folic acid, iodine and vitamin D3, with supporting nutrients for baby.   ",
};
export const ConceiveWellGold = {
    id: 8,
    text: "Conceive Well™ Gold",
    imageUrl: "conceive-gold.png",
    externalUrl: "https://www.blackmores.com.au/products/conceive-wellgold?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Pregnancy,
    claim: "Provides essential nutrients, including folic acid, iodine, iron, and omega-3 needed for healthy pre-conception.  ",
};
export const ConceiveWellMen = {
    id: 9,
    text: "Conceive Well Men",
    imageUrl: "conceive-men.png",
    externalUrl: "https://www.blackmores.com.au/products/conceive-well-men?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Pregnancy,
    claim: "Contains a comprehensive antioxidant formula to support preconception health in men.",
};
// Stress
export const StressSleepFormula = {
    id: 10,
    text: "Stress + Sleep",
    imageUrl: "stresssleep-daynight.png",
    externalUrl: "https://www.blackmores.com.au/products/stress-and-sleep?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Stress,
    claim: "A day and night formula to support a healthy stress response and relieves sleeplessness.  ",
};
export const SuperMag = {
    id: 11,
    text: "Super Magnesium+",
    imageUrl: "super-mag.png",
    externalUrl: "https://www.blackmores.com.au/products/super-magnesium-plus?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Move,
    claim: "A high-dose tablet to support muscle endurance, energy levels and reduce muscle cramps, tension and stiffness when dietary intake is inadequate.  ",
};
//  Fish
export const FishOilMini = {
    id: 12,
    text: "Fish Oil Mini Caps Odourless",
    imageUrl: "fishoil-mini.png",
    externalUrl: "https://www.blackmores.com.au/products/odourless-fish-oil-mini-caps?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Fish,
    claim: "Easy to swallow mini-size capsule, ideal for those who don’t eat 2-3 serves of fish per week to maintain heart, skin, eye and brain health.  ",
};
export const VeganFishOil = {
    id: 13,
    text: "Vegan Omega-3 Oil",
    imageUrl: "fishoil-vegan.png",
    externalUrl: "https://www.blackmores.com.au/products/vegan-omega-3-oil?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Fish,
    claim: "A vegan, vegetarian and fish-free source of omega-3. Same total omega-3 dose as standard fish oil in a mini-sized capsule to maintain heart, eye and brain health.  ",
};
// Alternatives
export const DailyMag = {
    id: 14,
    text: "Daily Magnesium",
    imageUrl: "daily-mag.png",
    externalUrl: "https://www.blackmores.com.au/products/daily-magnesium?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Move,
    claim: "Help relieve muscle cramps & mild spasms when dietary intake is inadequate.  ",
};
export const FolatePremiumIronVegan = {
    id: 15,
    text: "Folate",
    imageUrl: "folate.png",
    externalUrl: "https://www.blackmores.com.au/products/folate?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Move,
    claim: "A source of folic acid which helps prevent neural tube defects such as spina bifida and anencephaly when trying to conceive and during the first trimester of pregnancy.  ",
};
export const TranquilNight = {
    id: 16,
    text: "Tranquil Night",
    imageUrl: "tranquil-night.png",
    externalUrl: "https://www.blackmores.com.au/products/tranquil-night?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Move,
    claim: "Traditionally used in Western herbal medicine to relieve sleeplessness and calm the mind.",
};
export const StJohnsWort = {
    id: 17,
    text: "St John's Wort",
    imageUrl: "stjohnswort.png",
    externalUrl: "https://www.blackmores.com.au/products/st-johns-wort?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Move,
    claim: "Traditionally used in Western herbal medicine to support healthy mood balance & relieve symptoms of mild anxiety.",
};
export const BioCPowder = {
    id: 18,
    text: "Bio C® Powder",
    imageUrl: "bioc-powder.png",
    externalUrl: "https://www.blackmores.com.au/products/bio-c-powder?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Immunity,
    claim: "Easy to take, high potency dose of vitamin C that reduces the severity and duration of common cold symptoms.  ",
};
export const VitaminC500mg = {
    id: 19,
    text: "Vitamin C 500mg",
    imageUrl: "bioc-500mg.png",
    externalUrl: "https://www.blackmores.com.au/products/bio-c-chewable-500mg?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Immunity,
    claim: "Contains vitamin C in the form of ascorbic acid for immune system health and general health and wellbeing.",
};
export const DailyImmuneAction = {
    id: 20,
    text: "Blackmores Daily Immune Action",
    imageUrl: "dailyaction.png",
    externalUrl: "https://www.blackmores.com.au/products/daily-immune-action?utm_source=geronimo&utm_medium=ghc&utm_campaign=good-health-check&utm_content=results",
    applicableSection: Section.Immunity,
    claim: "Specially formulated with a daily blend of Vitamin C, Vitamin D, Zinc and Elderberry -  a convenient one-a-day tablet that supports immune health all year round.  ",
};
