<script lang="ts">
  import { profile } from "./stores/questions.store";

  import Header from "./components/Header.svelte";
  import TopCTA from "./components/TopCTA.svelte";
  import TopForm from "./components/TopForm.svelte";
  // Section
  import Recommendation from "./components/Recommendation.svelte";
  import Everyday from "./components/sections/Everyday.svelte";
  import Fish from "./components/sections/Fish.svelte";
  import Immunity from "./components/sections/Immunity.svelte";
  import Move from "./components/sections/Move.svelte";
  import Pets from "./components/sections/Pets.svelte";
  import Pregnancy from "./components/sections/Pregnancy.svelte";
  import Sleep from "./components/sections/Sleep.svelte";
  import Stress from "./components/sections/Stress.svelte";
  import { isSectionUnlocked, Section } from "./interfaces/interfaces";

  export let ver;

  console.info("v" + ver);
  $: showTopForm =
    $profile && // 👈 null and undefined check
    Object.keys($profile).length === 0 &&
    Object.getPrototypeOf($profile) === Object.prototype;
</script>

<Header />
<div class="bg-bm-orange  h-screen scroll-smooth">
  {#if showTopForm}
    <TopCTA />

    <TopForm />
  {:else if !isSectionUnlocked($profile, Section.Fish)}
    <Immunity />
    <div class:hidden={!isSectionUnlocked($profile, Section.Immunity)}>
      <Everyday />
    </div>
    <div class:hidden={!isSectionUnlocked($profile, Section.Everyday)}>
      <Pets />
    </div>
    <div class:hidden={!isSectionUnlocked($profile, Section.Pets)}>
      <Pregnancy />
    </div>
    <div class:hidden={!isSectionUnlocked($profile, Section.Pregnancy)}>
      <Stress />
    </div>
    <div class:hidden={!isSectionUnlocked($profile, Section.Stress)}>
      <Move />
    </div>
    <div class:hidden={!isSectionUnlocked($profile, Section.Move)}>
      <Sleep />
    </div>
    <div class:hidden={!isSectionUnlocked($profile, Section.Sleep)}>
      <Fish />
    </div>
  {:else}
    <div class:hidden={!isSectionUnlocked($profile, Section.Fish)}>
      <Recommendation />
    </div>
  {/if}
</div>

<style lang="postcss" global>
  /* Write your global styles here, in PostCSS syntax */
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
