import { QuestionBackgroundColor, QuestionDirection, TranslationXSpeed, } from "../interfaces/interfaces";
export const question = {
    text: "How would you rate the quality of your sleep? ",
    blurb: "Counting sheep will not help you fall asleep. It's just too boring to distract you from other thoughts!",
    questionColor: QuestionBackgroundColor.Purple,
    lottieUrl: "animations/sleep-large.json",
    lottieMobileUrl: "animations/sleep.json",
    stopMoUrls: [
        "images/stopmo/sleep-1.jpg",
        "images/stopmo/sleep-2.jpg",
        "images/stopmo/sleep-3.jpg",
        "images/stopmo/sleep-4.jpg",
        "images/stopmo/sleep-5.jpg",
    ],
    options: [
        {
            text: "Excellent, I sleep well and wake up refreshed",
            score: 1,
            id: 26,
        },
        {
            text: "OK. However, I have a little trouble falling asleep",
            score: 4,
            id: 27,
        },
        {
            text: "Not bad, I’ve had the occasional sleepless night",
            score: 8,
            id: 28,
        },
        {
            text: "Average, I sometimes wake intermittently throughout the night",
            score: 8,
            id: 29,
        },
    ],
    titles: [
        {
            text: "Sleep",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Sleep",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "Sleep",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Sleep",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "Sleep",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Sleep",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "Sleep",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.LeftToRight,
        },
    ],
    info: [
        {
            text: "Did",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "You",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Know",
            translationSpeed: TranslationXSpeed.Half,
            direction: QuestionDirection.RightToLeft,
        },
    ],
};
