import { QuestionBackgroundColor, QuestionDirection, TranslationXSpeed, } from "../interfaces/interfaces";
export const question = {
    text: "Do you suffer cramps during exercise?",
    blurb: "The strongest muscle based on size, is a muscle in your jaw called the masseter.",
    questionColor: QuestionBackgroundColor.Red,
    lottieUrl: "animations/move-large.json",
    lottieMobileUrl: "animations/move.json",
    posterImageUrl: "images/move.png",
    options: [
        {
            text: "No, never",
            score: 1,
            id: 13,
        },
        {
            text: "Rarely",
            score: 4,
            id: 14,
        },
        {
            text: "Yes, sometimes when I exercise!",
            score: 4,
            id: 15,
        },
        {
            text: "I don't exercise",
            score: 8,
            id: 16,
        },
    ],
    titles: [
        {
            text: "MOVE",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "MOVE",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "MOVE",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "MOVE",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "MOVE",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "MOVE",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "MOVE",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
    ],
    info: [
        {
            text: "Did",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "You",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Know",
            translationSpeed: TranslationXSpeed.Half,
            direction: QuestionDirection.RightToLeft,
        },
    ],
};
