<script lang="ts">
  import type { QuestionTitle, Section } from "../../interfaces/interfaces";

  import { onMount } from "svelte";

  import { inview } from "svelte-inview";
  import AnimatedText from "../animation/AnimatedText.svelte";
  import { currentSection } from "../../stores/questions.store";
  import MediaQuery from "../MediaQuery.svelte";

  export let questions: QuestionTitle[];
  export let sectionName: Section;
  export let triggerSectionSwap = false;
  export let animateTextFromCenter = false;
  let isInView;
  let yOffset;
  let thisElement: HTMLElement;

  onMount(() => {
    yOffset = thisElement.getBoundingClientRect().top; // thisElement.offsetTop;
  });
</script>

<section
  class={$$props.class}
  bind:this={thisElement}
  use:inview={{}}
  on:change={(event) => {
    const { inView } = event.detail;
    isInView = inView;
    if (inView && triggerSectionSwap) {
      $currentSection = sectionName;
    }
  }}
>
  <MediaQuery query="(min-width: 640px)" let:matches>
    {#if matches}
      <AnimatedText
        class={$$props.textclass + "text-8xl"}
        {questions}
        parentElement={thisElement}
        centerText={animateTextFromCenter}
        {yOffset}
      />
    {/if}
  </MediaQuery>

  <MediaQuery query="(max-width: 640px)" let:matches>
    {#if matches}
      <AnimatedText
        class={$$props.textclass + "text-6xl"}
        {questions}
        parentElement={thisElement}
        centerText={animateTextFromCenter}
        {yOffset}
      />
    {/if}
  </MediaQuery>

  <slot />
  <slot name="lottie" />
</section>
