import { Section, } from "../interfaces/interfaces";
import * as everyday from "../stores/everyday.ds";
import * as fish from "../stores/fish.ds";
import * as immunity from "../stores/immunity.ds";
import * as move from "../stores/move.ds";
import * as pets from "../stores/pets.ds";
import * as pregnancy from "../stores/pregnancy.ds";
import * as sleep from "../stores/sleep.ds";
import * as stress from "../stores/stress.ds";
import { noopStorage, persist, sessionStorage, } from "@macfja/svelte-persistent-store";
import { writable } from "svelte/store";
import * as recommends from "./products/recommendations.ds";
export const profile = persist(writable({}), sessionStorage(), "profile");
export const answers = persist(writable({}), sessionStorage(), "answers");
export const recommendations = persist(writable({
    recommendations: [
        recommends.BioC1000,
        recommends.ConceiveWellGold,
        recommends.ConceiveWellMen,
        recommends.FishOilMini,
        recommends.Multi50Plus,
        recommends.MultiMen,
        recommends.MultiWomen,
        recommends.PregnancyBreastFeedingGold,
        recommends.StressSleepFormula,
        recommends.SuperMag,
        recommends.SustainedRelease,
        recommends.VeganFishOil,
        recommends.BioCPowder,
        recommends.VitaminC500mg,
        recommends.WomensEnergyEffervescent,
    ],
}), sessionStorage(), "recommendations");
export const questions = persist(writable({
    immunity: immunity.question,
    everyday: everyday.question,
    pets: pets.question,
    pregnancy: pregnancy.question,
    stress: stress.question,
    move: move.question,
    sleep: sleep.question,
    fish: fish.question,
}), sessionStorage(), "questions");
export const scores = persist(writable({
    fish: 0,
    immunity: 0,
    move: 0,
    multi: 0,
    pets: 0,
    pregnancy: 0,
    sleep: 0,
    stress: 0,
}), sessionStorage(), "scores");
export const currentSection = persist(writable(Section.Immunity), noopStorage(), "currentSection");
