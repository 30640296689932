<script lang="ts">
  import { SectionLookup } from "../interfaces/lookups";
  import { scrollTo } from "svelte-scrolling";
  import { questions, currentSection } from "../stores/questions.store";
  import { LightTooltip } from "flowbite-svelte";
  $: questionNames = Object.keys($questions);

  
</script>

<div class="flex flex-row justify-center items-center">
  {#each questionNames as name, i}
    <div class="cursor-pointer" use:scrollTo={name}>
      <button
      type="button"
      class="w-6 h-1 mx-1 my-2 bg-white {i <= SectionLookup[$currentSection]
        ? 'opacity-100'
        : 'opacity-30'}"
      aria-current="true"
      aria-label="Section {name}"

    />
<!--       
      <LightTooltip  tip={name} bottom>
        <button
          type="button"
          class="w-6 h-1 mx-1 my-2 bg-white {i <= SectionLookup[$currentSection]
            ? 'opacity-100'
            : 'opacity-30'}"
          aria-current="true"
          aria-label="Section {name}"
          data-tooltip-target="tooltip-{name}"
          data-tooltip-placement="bottom"
        />
      </LightTooltip> -->
    </div>
  {/each}
</div>
