<script>
  import { onMount } from "svelte";

  import { scrollTo } from "svelte-scrolling";
</script>

<section class="flex flex-col justify-between pt-12 md:pt-0 bg-bm-red ">
  <div class="flex flex-col md:flex-row w-full">
    <div class="w-full md:w-1/2">
      <div class="px-4 md:mx-32 flex flex-col justify-start pb-20 md:pb-32">
        <h1
          class="font-dmsans font-bold text-5xl text-bm-text-red mt-12 md:mt-44 max-w-md"
        >
          GOOD HEALTH CHECK
        </h1>
        <p
          class="font-dmsans text-2xl mt-16 text-white w-3/4 md:w-full md:max-w-xs"
        >
          Let's see if we can help you with your overall health and lifestyle
        </p>
        <br />
        <p class="font-dmsans text-xl text-white w-3/4 md:w-full">
          It'll be two minutes well spent.
        </p>
      </div>

      <div
        class=" flex flex-col justify-center items-start px-4 md:w-full md:mx-32 pb-32"
      >
        <button
          class="bg-white rounded-full text-bm-red font-bold text-lg max-w-sm  h-12 w-48"
          use:scrollTo={"topform"}>Start check</button
        >
      </div>
    </div>
    <div class="md:bg-cta-bg bg-center bg-cover  w-full md:w-1/2">
      <img src="images/move.png" alt="Move" class="block md:hidden" />
    </div>
  </div>
</section>
