import { QuestionBackgroundColor, QuestionDirection, TranslationXSpeed, } from "../interfaces/interfaces";
export const question = {
    text: "How would you rate your stress levels?",
    blurb: "Laughing will reduce stress and support the immune system by releasing dopamine and other health-enhancing hormones and chemicals in the brain.",
    questionColor: QuestionBackgroundColor.Gold,
    lottieUrl: "animations/stress-large.json",
    lottieMobileUrl: "animations/stress.json",
    stopMoUrls: [
        "images/stopmo/stress-1.jpg",
        "images/stopmo/stress-2.jpg",
        "images/stopmo/stress-3.jpg",
        "images/stopmo/stress-4.jpg",
        "images/stopmo/stress-5.jpg",
    ],
    options: [
        {
            text: "What stress! I'm super chill",
            score: 1,
            id: 30,
        },
        {
            text: "Nothing a morning coffee can't fix",
            score: 1,
            id: 31,
        },
        {
            text: "There are couple of circumstances I'm aware of that trigger stress",
            score: 4,
            id: 32,
        },
        {
            text: "I occasionally feel stressed and/or mildly anxious",
            score: 8,
            id: 33,
        },
    ],
    titles: [
        {
            text: "Stress",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Stress",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "Stress",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Stress",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "Stress",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Stress",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "Stress",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.LeftToRight,
        },
    ],
    info: [
        {
            text: "Did",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "You",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Know",
            translationSpeed: TranslationXSpeed.Half,
            direction: QuestionDirection.RightToLeft,
        },
    ],
};
