import { QuestionBackgroundColor, QuestionDirection, TranslationXSpeed, } from "../interfaces/interfaces";
export const question = {
    text: "What best describes your current situation?",
    blurb: "'Pregnancy brain' is a real thing - a woman's brain will temporarily shrink during pregnancy.",
    questionColor: QuestionBackgroundColor.Pink,
    lottieUrl: "animations/pregnancy-large.json",
    lottieMobileUrl: "animations/pregnancy.json",
    posterImageUrl: "images/pregnancy.png",
    options: [
        {
            text: "I already have children and I remember pregnancy brain well!",
            altTextMen: "I already have children",
            altTextNonSpecific: "I already have children",
            score: 1,
            id: 21,
        },
        {
            text: "Children? No, thank you! ",
            altTextMen: "Children? No, thank you! ",
            altTextNonSpecific: "Children? No, thank you! ",
            score: 1,
            id: 22,
        },
        {
            text: "I'm currently expecting",
            altTextMen: "My partner is currently expecting",
            altTextNonSpecific: "I am pregnant",
            score: 2,
            id: 23,
        },
        {
            text: "",
            altTextMen: "",
            altTextNonSpecific: "My partner is pregnant",
            score: 1,
            id: 44,
        },
        {
            text: "I'm trying to conceive",
            altTextMen: "No, but hopefully soon! ",
            altTextNonSpecific: "I’m trying to conceive",
            score: 1,
            id: 24,
        },
    ],
    titles: [
        {
            text: "PREGNANCY",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "PREGNANCY",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "PREGNANCY",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "PREGNANCY",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "PREGNANCY",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "PREGNANCY",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "PREGNANCY",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.RightToLeft,
        },
    ],
    info: [
        {
            text: "Did",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "You",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Know",
            translationSpeed: TranslationXSpeed.Half,
            direction: QuestionDirection.RightToLeft,
        },
    ],
};
