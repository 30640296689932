export const findElementPosition = (elem) => {
    var location = 0;
    if (elem.offsetParent) {
        do {
            location += elem.offsetTop;
            elem = elem.offsetParent;
        } while (elem);
    }
    return location >= 0 ? location : 0;
};
export const distanceFromTopDocument = (el) => {
    var _a;
    return window !== undefined
        ? (_a = window.pageYOffset + (el === null || el === void 0 ? void 0 : el.getBoundingClientRect().top)) !== null && _a !== void 0 ? _a : 0
        : 0;
};
