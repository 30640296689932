export var Section;
(function (Section) {
    Section["Immunity"] = "immunity";
    Section["Everyday"] = "everyday";
    Section["Pets"] = "pets";
    Section["Pregnancy"] = "pregnancy";
    Section["Stress"] = "stress";
    Section["Move"] = "move";
    Section["Sleep"] = "sleep";
    Section["Fish"] = "fish";
    Section["Final"] = "recommendations";
})(Section || (Section = {}));
export var TranslationXSpeed;
(function (TranslationXSpeed) {
    TranslationXSpeed[TranslationXSpeed["Half"] = 0] = "Half";
    TranslationXSpeed[TranslationXSpeed["ThreeQuarters"] = 1] = "ThreeQuarters";
    TranslationXSpeed[TranslationXSpeed["Normal"] = 2] = "Normal";
    TranslationXSpeed[TranslationXSpeed["Double"] = 3] = "Double";
    TranslationXSpeed[TranslationXSpeed["Triple"] = 4] = "Triple";
})(TranslationXSpeed || (TranslationXSpeed = {}));
export var QuestionSectionContext;
(function (QuestionSectionContext) {
    QuestionSectionContext[QuestionSectionContext["Title"] = 0] = "Title";
    QuestionSectionContext[QuestionSectionContext["Info"] = 1] = "Info";
    QuestionSectionContext[QuestionSectionContext["Question"] = 2] = "Question";
})(QuestionSectionContext || (QuestionSectionContext = {}));
export var QuestionBackgroundColor;
(function (QuestionBackgroundColor) {
    QuestionBackgroundColor[QuestionBackgroundColor["Orange"] = 0] = "Orange";
    QuestionBackgroundColor[QuestionBackgroundColor["Blue"] = 1] = "Blue";
    QuestionBackgroundColor[QuestionBackgroundColor["Teal"] = 2] = "Teal";
    QuestionBackgroundColor[QuestionBackgroundColor["Pink"] = 3] = "Pink";
    QuestionBackgroundColor[QuestionBackgroundColor["Gold"] = 4] = "Gold";
    QuestionBackgroundColor[QuestionBackgroundColor["Red"] = 5] = "Red";
    QuestionBackgroundColor[QuestionBackgroundColor["Purple"] = 6] = "Purple";
    QuestionBackgroundColor[QuestionBackgroundColor["Green"] = 7] = "Green";
})(QuestionBackgroundColor || (QuestionBackgroundColor = {}));
export var QuestionDirection;
(function (QuestionDirection) {
    QuestionDirection[QuestionDirection["LeftToRight"] = 0] = "LeftToRight";
    QuestionDirection[QuestionDirection["RightToLeft"] = 1] = "RightToLeft";
})(QuestionDirection || (QuestionDirection = {}));
export function isSectionUnlocked(profile, section) {
    var _a;
    return (_a = profile.sectionsUnlocked) === null || _a === void 0 ? void 0 : _a.includes(section);
}
export function getTitleColors(question) {
    let titleTextClass;
    let titleBgClass;
    let questionHoverTextClass;
    let baseColor;
    /*
    "bm-orange": "#F1801F",
        "bm-text-orange": "#F8CBA4",
        "bm-blue": "#0031A7",
        "bm-text-blue": "#8DADD5",
        "bm-teal": "#00B2A5",
        "bm-text-teal": "#B2E7E4",
        "bm-pink": "#E06FA9",
        "bm-text-pink": "#F1CEE0",
        "bm-gold": "#A78C3A",
        "bm-text-gold": "#D6CBA8",
        "bm-red": "#EC4F22",
        "bm-text-red": "#F4AA96",
        "bm-purple": "#6C3A95",
        "bm-text-purple": "#C1AFD3",
        "bm-green": "#00A5B2",
        "bm-text-green": "#93D2D0",
        "bm-recommendation-teal": "#33B7C1",
    */
    switch (question.questionColor) {
        case QuestionBackgroundColor.Blue:
            titleTextClass = "text-bm-blue";
            titleBgClass = "bg-bm-text-blue";
            questionHoverTextClass = "text-bm-blue";
            baseColor = "#0031A7";
            break;
        case QuestionBackgroundColor.Gold:
            titleTextClass = "text-bm-gold";
            titleBgClass = "bg-bm-text-gold";
            questionHoverTextClass = "text-bm-gold";
            baseColor = "#A78C3A";
            break;
        case QuestionBackgroundColor.Green:
            titleTextClass = "text-bm-green";
            titleBgClass = "bg-bm-text-green";
            questionHoverTextClass = "text-bm-green";
            baseColor = "#00A5B2";
            break;
        case QuestionBackgroundColor.Orange:
            titleTextClass = "text-bm-orange";
            titleBgClass = "bg-bm-text-orange";
            questionHoverTextClass = "text-bm-orange";
            baseColor = "#F1801F";
            break;
        case QuestionBackgroundColor.Pink:
            titleTextClass = "text-bm-pink";
            titleBgClass = "bg-bm-text-pink";
            questionHoverTextClass = "text-bm-pink";
            baseColor = "#E06FA9";
            break;
        case QuestionBackgroundColor.Purple:
            titleTextClass = "text-bm-purple";
            titleBgClass = "bg-bm-text-purple";
            questionHoverTextClass = "text-bm-purple";
            baseColor = "#6C3A95";
            break;
        case QuestionBackgroundColor.Red:
            titleTextClass = "text-bm-red";
            titleBgClass = "bg-bm-text-red";
            questionHoverTextClass = "text-bm-red";
            baseColor = "#EC4F22";
            break;
        case QuestionBackgroundColor.Teal:
            titleTextClass = "text-bm-teal";
            titleBgClass = "bg-bm-text-teal";
            questionHoverTextClass = "text-bm-teal";
            baseColor = "#00B2A5";
            break;
        default:
            titleTextClass = "text-bm-orange";
            titleBgClass = "bg-bm-text-orange";
            questionHoverTextClass = "text-bm-orange";
            baseColor = "#F1801F";
            break;
    }
    return {
        titleTextClass,
        titleBgClass,
        questionHoverTextClass,
        baseColor,
    };
}
export function getInfoColors(question) {
    let infoBgClass;
    let infoTextClass;
    let questionHoverTextClass;
    switch (question.questionColor) {
        case QuestionBackgroundColor.Blue:
            infoBgClass = "bg-bm-blue";
            infoTextClass = "text-bm-text-blue";
            questionHoverTextClass = "text-bm-blue";
            break;
        case QuestionBackgroundColor.Gold:
            infoBgClass = "bg-bm-gold";
            infoTextClass = "text-bm-text-gold";
            questionHoverTextClass = "text-bm-gold";
            break;
        case QuestionBackgroundColor.Green:
            infoBgClass = "bg-bm-green";
            infoTextClass = "text-bm-text-green";
            questionHoverTextClass = "text-bm-green";
            break;
        case QuestionBackgroundColor.Orange:
            infoBgClass = "bg-bm-orange";
            infoTextClass = "text-bm-text-orange";
            questionHoverTextClass = "text-bm-orange";
            break;
        case QuestionBackgroundColor.Pink:
            infoBgClass = "bg-bm-pink";
            infoTextClass = "text-bm-text-pink";
            questionHoverTextClass = "text-bm-pink";
            break;
        case QuestionBackgroundColor.Purple:
            infoBgClass = "bg-bm-purple";
            infoTextClass = "text-bm-text-purple";
            questionHoverTextClass = "text-bm-purple";
            break;
        case QuestionBackgroundColor.Red:
            infoBgClass = "bg-bm-red";
            infoTextClass = "text-bm-text-red";
            questionHoverTextClass = "text-bm-red";
            break;
        case QuestionBackgroundColor.Teal:
            infoBgClass = "bg-bm-teal";
            infoTextClass = "text-bm-text-teal";
            questionHoverTextClass = "text-bm-teal";
            break;
        default:
            infoBgClass = "bg-bm-orange";
            infoTextClass = "text-bm-text-orange";
            questionHoverTextClass = "text-bm-orange";
            break;
    }
    return { infoBgClass, infoTextClass, questionHoverTextClass };
}
