<script lang="ts">
  import { onMount } from "svelte";
  import { inview } from "svelte-inview";
  import { distanceFromTopDocument } from "../../lib/positioning";

  import {
    QuestionDirection,
    TranslationXSpeed,
    type QuestionTitle,
  } from "../../interfaces/interfaces";

  export let questions: QuestionTitle[];
  export let yOffset: number;
  export let parentElement: HTMLElement;
  export let centerText = false;
  $: directionClass = "";
  $: outerWidth = 0;
  $: windowY = 0;
  $: xOffset = windowY - yOffset;
  $: centerX = outerWidth / 2;
  let infoInView = false;

  $: distanceFromTop = windowY - distanceFromTopDocument(parentElement);

  function getStartXFromDirection(baseXValue, direction) {
    if (direction === QuestionDirection.RightToLeft) {
      return baseXValue * -1; // counter and positive offset.
    }

    return baseXValue;
  }
  function convertTranslationSpeedToCssMultiplier(
    speed: TranslationXSpeed,
    baseXValue: number,
    direction: QuestionDirection
  ) {
    // if (!infoInView) {
    //   return "--text-x-coord:" + firstxValue;
    // }
    switch (speed) {
      case TranslationXSpeed.Half:
        return `--text-x-coord:${
          getStartXFromDirection(baseXValue, direction) * 0.5
        }px;`;

      case TranslationXSpeed.ThreeQuarters:
        return `--text-x-coord:${
          getStartXFromDirection(baseXValue, direction) * 0.75
        }px;`;

      case TranslationXSpeed.Normal:
        return `--text-x-coord:${getStartXFromDirection(
          baseXValue,
          direction
        )}px;`;

      case TranslationXSpeed.Double:
        return `--text-x-coord:${
          getStartXFromDirection(baseXValue, direction) * 2
        }px;`;

      case TranslationXSpeed.Triple:
        return `--text-x-coord:${
          getStartXFromDirection(baseXValue, direction) * 3
        }px;`;

      default:
        return `--text-x-coord:${getStartXFromDirection(
          baseXValue,
          direction
        )}px;`;
    }
  }
</script>

<svelte:window bind:innerWidth={outerWidth} bind:scrollY={windowY} />

<div
  use:inview={{}}
  on:change={(event) => {
    const { inView } = event.detail;
    infoInView = inView;
  }}
/>
{#if questions}
  {#each questions as info}
    <div
      class="{directionClass} {$$props.class} transformer z-30 {centerText
        ? 'self-center'
        : ''}"
      style={convertTranslationSpeedToCssMultiplier(
        info.translationSpeed,
        distanceFromTop,
        info.direction
      )}
    >
      {info.text}
    </div>
  {/each}
{/if}

<style>
  .transformer {
    content: var(--text-x-coord, 0);
    transform: translateX(var(--text-x-coord, 0));
  }
</style>
