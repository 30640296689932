<script>
  import { isSectionUnlocked, Section } from "../interfaces/interfaces";
  import { profile } from "../stores/questions.store";
  import Indicators from "./Indicators.svelte";

  $: showTopForm =
    $profile && // 👈 null and undefined check
    Object.keys($profile).length === 0 &&
    Object.getPrototypeOf($profile) === Object.prototype;
</script>

<nav
  class="fixed  w-full bg-transparent border-gray-200 py-4 rounded flex flex-col sm:flex-row justify-evenly mt-4 z-40"
>
  <div class="px-4 sm:mx-32 flex flex-col sm:flex-row justify-between w-full">
    <div
      class="flex flex-wrap justify-center sm:justify-start items-center mb-4 sm:mb-0"
    >
      <img src="images/logo.png" alt="" width="147" />
    </div>

    {#if !showTopForm && !isSectionUnlocked($profile, Section.Fish)}
      <Indicators />
    {/if}
  </div>
</nav>
