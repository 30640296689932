// import { ProductRecommendation, Section } from "./interfaces";
import { get } from "svelte/store";
import { BioC1000, BioCPowder, ConceiveWellGold, ConceiveWellMen, FishOilMini, Multi50Plus, MultiMen, MultiWomen, PregnancyBreastFeedingGold, StressSleepFormula, SuperMag, SustainedRelease, VeganFishOil, VitaminC500mg, WomensEnergyEffervescent, FolatePremiumIronVegan, DailyMag, TranquilNight, StJohnsWort, DailyImmuneAction, } from "../stores/products/recommendations.ds";
import { answers, profile } from "../stores/questions.store";
import { Section } from "./interfaces";
export let scoringScaleFactor = 1;
/* from interfaces */
export let SectionLookup = {
    immunity: 0,
    everyday: 1,
    pets: 2,
    pregnancy: 3,
    stress: 4,
    move: 5,
    sleep: 6,
    fish: 7,
};
export let SectionAnswerRanking = {
    pregnancy: 1,
    immunity: 2,
    move: 3,
    everyday: 4,
    sleep: 5,
    stress: 6,
    fish: 7,
    pets: 8,
};
export let SectionRecommendationBlurb = {
    pregnancy: "Based on the answers you’ve provided, it looks like good health support during pregnancy is important. Consult your health care provider for regular check ups and advice during this time to ensure you are following a healthy diet, receive recommendations on exercise and minimise your stress levels.",
    pregnancyConceiving: "Based on the answers you’ve provided, good health when trying for a baby is important. Consult your health care provider for regular check ups and advice during this time to ensure you are following a healthy diet, receive recommendations on exercise and minimise your stress levels.",
    pregnancyConceivingMen: "Based on the answers you’ve provided, good health when trying for a baby is important not only for women but also men. Increase your intake of foods that contain folic acid like green vegetables, fruits, legumes, nuts and even vegemite!”",
    immunity: "Based on the answers you’ve provided, it looks like you could do with some tips to help support your immune health. Start with the diet – eat a range of healthy foods to give your body the nutrients it needs to stay healthy, include antioxidants such as vitamin C and foods containing zinc and garlic. Drink plenty of water, exercise regularly and manage your stress levels.",
    move: "Exercise is an important part of a healthy and balanced lifestyle. Find a way to move your body in a format that you love. It could be walking, running, swimming, dancing, just make sure you move and move frequently.",
    everyday: "Based on the answers you’ve provided, it looks like you could use some tips to support your general health and wellbeing. Start with the basics, a healthy and varietal diet, lots of water, exercise regularly and minimise your stress levels.",
    sleep: "Based on the answers you’ve provided, it looks like you could look to support healthy sleep . Get started with the basics – ensure you have a comfortable mattress and pillow, reduce your caffeine after 3pm and avoid eating large, rich or spicy meals within 2 hours of your bedtime.",
    stress: "Based on the answers you’ve provided, it looks like stress may be an area that you could use some general help in. Try to set some time aside to do the things that make you happy; read a book, go for a walk, try meditation.",
    fish: "Based on the answers you’ve provided, it looks like you may benefit from general health support that Omega-3 can provide. Omega-3 benefits many aspects of our general health including your heart, skin, eyes, liver and bones. Unfortunately, our bodies don’t naturally produce Omega-3 so sometimes we need some extra assistance getting the serve that we need.",
    pets: "",
    perfectscore: "Well done, you are doing a great job looking after your overall health. Take preventative steps to support your immune health and general health and wellbeing. Vitamin C, D and Zinc - are all essential for the immune system.",
};
export const getRecommendationBlurb = (products) => {
    // console.log("Getting recommendation blurb: ", products);
    if (products.some((product) => product.id === 20)) {
        // immune action
        return SectionRecommendationBlurb.perfectscore;
    }
    else if (products.some((product) => product.applicableSection === Section.Pregnancy)) {
        if (products.some((product) => product.id === 8)) {
            // Conceive well gold
            return SectionRecommendationBlurb.pregnancyConceiving;
        }
        else if (products.some((product) => product.id === 9)) {
            // Conceive well men
            return SectionRecommendationBlurb.pregnancyConceivingMen;
        }
        else {
            return SectionRecommendationBlurb.pregnancy;
        }
    }
    else if (products.some((product) => product.applicableSection === Section.Immunity)) {
        return SectionRecommendationBlurb.immunity;
    }
    else if (products.some((product) => product.applicableSection === Section.Move)) {
        return SectionRecommendationBlurb.move;
    }
    else if (products.some((product) => product.applicableSection === Section.Everyday)) {
        return SectionRecommendationBlurb.everyday;
    }
    else if (products.some((product) => product.applicableSection === Section.Sleep) ||
        products.some((product) => product.applicableSection === Section.Stress)) {
        let answerStore = get(answers);
        // check if sleep was
        if (answerStore.sleep === 28 || answerStore.sleep === 29) {
            // answered negative to sleep so offer the sleep blurb
            return SectionRecommendationBlurb.sleep;
        }
        // or choose stress
        return SectionRecommendationBlurb.stress;
    }
    else if (products.some((product) => product.applicableSection === Section.Sleep)) {
        return SectionRecommendationBlurb.sleep;
    }
    else if (products.some((product) => product.applicableSection === Section.Stress)) {
        return SectionRecommendationBlurb.stress;
    }
    else if (products.some((product) => product.applicableSection === Section.Fish)) {
        return SectionRecommendationBlurb.fish;
    }
    return "";
};
export const getRecommendableProducts = () => {
    let answerStore = get(answers);
    let profileStore = get(profile);
    // console.log("Getting Product Recommendation");
    let isMale = profileStore.gender === "male";
    let isFemale = profileStore.gender === "female";
    let isPregnant = answerStore.pregnancy === 23 && !isMale; // If Male ignore
    let isTryingToConceive = answerStore.pregnancy === 24;
    let isVegan = answerStore.fish === 5;
    let isNonSpecific = !(profileStore.gender === "male" || profileStore.gender === "female");
    // Recommend: BioC1000
    let prBioC1000Rule = (answerStore.immunity === 11 || answerStore.immunity === 12) &&
        !isPregnant &&
        !isTryingToConceive; // everyone except pregnant women
    // Recommend: BioC powder
    let prBioCPowder = (answerStore.immunity === 11 || answerStore.immunity === 12) &&
        !isPregnant &&
        isTryingToConceive; // everyone except conceiving folk
    //Recommend: VitaminC500
    let prVitaminC500mg = (answerStore.immunity === 11 || answerStore.immunity === 12) &&
        isPregnant &&
        !isVegan; // everyone except pregnant women
    // Recommend: Breast Feeding gold
    let prBreastfeedingGold = answerStore.pregnancy === 23 && !isVegan && (isFemale || isNonSpecific);
    // Recommend: Breastfeeding Vegan
    let prBreastfeedingFolateForVegan = answerStore.pregnancy === 23 && isVegan && (isFemale || isNonSpecific); // assuming female only
    // Recommend: Conceive well Gold
    let prConceiveWellGold = isTryingToConceive && !isVegan && (isFemale || isNonSpecific);
    // Recommend: Folate
    let prConceiveWellGoldVeganFolate = isTryingToConceive && isVegan && (isFemale || isNonSpecific);
    // Recommend: Conceive Well Men
    let prConceiveWellMen = isTryingToConceive && isMale;
    // Recommend: Multi 50 Plus
    let prPlus50MultisRule = (answerStore.everyday === 3 || answerStore.everyday === 4) &&
        !isPregnant &&
        !isTryingToConceive &&
        !isVegan &&
        profileStore.age === "50plus";
    // Recommend: Multi for Women
    let prPlusWomenMultisRule = (answerStore.everyday === 3 || answerStore.everyday === 4) &&
        !isPregnant &&
        !isTryingToConceive &&
        !isVegan &&
        isFemale &&
        profileStore.age !== "50plus";
    // Recommend: Multi for Men
    let prPlusMenMultisRule = (answerStore.everyday === 3 || answerStore.everyday === 4) &&
        isMale &&
        !isTryingToConceive &&
        profileStore.age !== "50plus";
    // Recommend: Sustained Release
    let prPlusNonGenderSpecificSelectionMultisRule = (answerStore.everyday === 3 || answerStore.everyday === 4) &&
        !isPregnant &&
        !isTryingToConceive &&
        !isVegan &&
        isNonSpecific &&
        profileStore.age !== "50plus";
    // Recommend: Women's Effervescents
    let prPlusEnergyEffervescents = (answerStore.everyday === 3 || answerStore.everyday === 4) &&
        (isPregnant || isTryingToConceive) &&
        !isMale &&
        (isNonSpecific || isFemale);
    // Recommend: Stress & Sleep
    let prStressAndSleep = (answerStore.stress === 32 ||
        answerStore.stress === 33 ||
        answerStore.sleep === 28 ||
        answerStore.sleep === 29) &&
        !isPregnant &&
        !isTryingToConceive;
    let prStressContinued = prStressAndSleep &&
        (answerStore.stress === 32 || answerStore.stress === 33) &&
        (answerStore.sleep === 28 || answerStore.sleep === 29) &&
        (answerStore.move === 14 || answerStore.move === 15);
    // Product: St. John Wort & Super Mag +
    let prStJohnsWort = (answerStore.stress === 32 || answerStore.stress === 33) &&
        (answerStore.move === 14 || answerStore.move === 15) &&
        !(answerStore.sleep === 28 || answerStore.sleep === 29) &&
        !isVegan &&
        !isPregnant &&
        !isTryingToConceive;
    // Recommend: Womens Effervescents
    let prStressEffervescentRelief = (answerStore.stress === 32 || answerStore.stress === 33) &&
        !isVegan &&
        (isPregnant || (!isMale && isTryingToConceive));
    // Recommend: Sleep and Stress      //// and Daily Magnesium
    let prStressSleepReliefVegan = (answerStore.stress === 32 || answerStore.stress === 33) &&
        isVegan &&
        !isPregnant &&
        !isTryingToConceive;
    // Recommend: Super Mag + Tranquil
    let prTranquil = (answerStore.sleep === 28 || answerStore.sleep === 29) &&
        !(answerStore.stress === 32 || answerStore.stress === 33) &&
        (answerStore.move === 14 || answerStore.move === 15) &&
        !isVegan &&
        !isPregnant &&
        !isTryingToConceive;
    // Recommend: Women's Effervescent
    let prSleepEffervescenttAid = (answerStore.sleep === 28 || answerStore.sleep === 29) &&
        isPregnant &&
        !isMale &&
        isTryingToConceive;
    /*
      Show Super magnesium: Move is every time or occasional  (NO VEGAN/ PREGNANT/TRYING)
      Show Super magnesium: Move is every time or occasional  + Sleep is average (NO VEGAN/ PREGNANT/TRYING)
      Show Super magnesium: Move is every time or occasional  + Stress is Occasional (NO VEGAN/ PREGNANT/TRYING)
  
      DON’T SHOP SUPER MAG – IF VEGAN, PREGNANT, TRYING AND
      Don’t show Super Magnesium: Move is occasional  + Sleep is poor (we want S&S plus Daily Mag)
      Don’t show Super Magnesium: Move is occasional  + Stress is daily (we want S&S plus Daily Mag)
      Don’t show Super magnesium: Move is every time AND sleep is poor or average AND stress is daily or occasional  (in this case we want S&S and daily magnesium)
    */
    let notConceivingPregnant = !isPregnant && !isTryingToConceive;
    let negMoveOccasionalSleepPoor = answerStore.move === 14 && answerStore.sleep == 29; // S&S Daily Mag instead
    let negMoveOccasionalStressDaily = answerStore.move === 14 && answerStore.stress == 33; // S&S Daily Mag instead
    let negMoveEveryTimeAndSleepPoorAndStressed = answerStore.move === 15 &&
        (answerStore.sleep === 28 || answerStore.sleep === 29) &&
        (answerStore.stress === 32 || answerStore.stress === 33); // only S&S and Daily Mag
    let moveOccasionalOrPoor = (answerStore.move === 14 || answerStore.move === 15) &&
        notConceivingPregnant;
    let moveOccasionalOrPoorSleepAverage = moveOccasionalOrPoor && answerStore.sleep === 28;
    let moveOccasionalOrPoorStressOccassional = moveOccasionalOrPoor && answerStore.stress === 32;
    let prSuperMagBasic = moveOccasionalOrPoor && notConceivingPregnant;
    // Recommend: Daily Mag
    // only: move negative.
    let prDailyMag = (answerStore.move === 14 || answerStore.move === 15) &&
        (answerStore.sleep === 28 || answerStore.sleep === 29) &&
        (answerStore.stress === 32 || answerStore.stress === 33) &&
        isVegan &&
        !isPregnant &&
        !isTryingToConceive;
    // Fish
    let fishOilMini = (answerStore.fish === 7 || answerStore.fish === 6) &&
        !isPregnant &&
        !isTryingToConceive;
    let veganFishOil = 
    // (answerStore.fish === 7 || answerStore.fish === 6) &&
    isVegan && !isPregnant && !isTryingToConceive;
    // Recommendations allowed
    let recommendations = [];
    // Immunity
    if (prBioC1000Rule) {
        recommendations.push(BioC1000);
    }
    if (prBioCPowder) {
        recommendations.push(BioCPowder);
    }
    if (prVitaminC500mg) {
        recommendations.push(VitaminC500mg);
    }
    // Everyday
    if (prPlus50MultisRule) {
        recommendations.push(Multi50Plus);
    }
    if (prPlusWomenMultisRule) {
        recommendations.push(MultiWomen);
    }
    if (prPlusMenMultisRule) {
        recommendations.push(MultiMen);
    }
    if (prPlusNonGenderSpecificSelectionMultisRule) {
        recommendations.push(SustainedRelease);
    }
    if (prPlusEnergyEffervescents ||
        prSleepEffervescenttAid ||
        prStressEffervescentRelief) {
        recommendations.push(WomensEnergyEffervescent);
    }
    // pregnancy
    if (prBreastfeedingGold) {
        recommendations.push(PregnancyBreastFeedingGold);
        recommendations.push(WomensEnergyEffervescent);
    }
    if (prBreastfeedingFolateForVegan || prConceiveWellGoldVeganFolate) {
        recommendations.push(FolatePremiumIronVegan);
    }
    // stress
    if (prStressAndSleep) {
        recommendations.push(StressSleepFormula);
    }
    if (negMoveOccasionalSleepPoor ||
        negMoveOccasionalStressDaily ||
        negMoveEveryTimeAndSleepPoorAndStressed) {
        // show S&S and Daily Mag
        recommendations.push(StressSleepFormula);
        recommendations.push(DailyMag);
    }
    else if (prSuperMagBasic ||
        moveOccasionalOrPoorSleepAverage ||
        moveOccasionalOrPoorStressOccassional) {
        if (isVegan) {
            // show super mag
            recommendations.push(DailyMag);
        }
        else {
            recommendations = removeItemAll(recommendations, StressSleepFormula);
            recommendations = removeItemAll(recommendations, Multi50Plus);
            recommendations = removeItemAll(recommendations, MultiMen);
            recommendations = removeItemAll(recommendations, MultiWomen);
            recommendations.push(SuperMag);
        }
    }
    if (prTranquil) {
        recommendations.push(TranquilNight);
    }
    if (fishOilMini) {
        recommendations.push(FishOilMini);
    }
    if (veganFishOil) {
        recommendations.push(VeganFishOil);
    }
    if (prStJohnsWort) {
        recommendations.push(StJohnsWort);
    }
    if (prConceiveWellGold) {
        recommendations.push(ConceiveWellGold);
    }
    if (prConceiveWellMen) {
        recommendations = removeItemAll(recommendations, StressSleepFormula);
        recommendations = removeItemAll(recommendations, Multi50Plus);
        recommendations = removeItemAll(recommendations, MultiMen);
        recommendations = removeItemAll(recommendations, MultiWomen);
        recommendations.push(ConceiveWellMen);
    }
    if (prBreastfeedingGold) {
        recommendations = removeItemAll(recommendations, VitaminC500mg);
        recommendations = removeItemAll(recommendations, BioC1000);
        recommendations = removeItemAll(recommendations, BioCPowder);
        recommendations = removeItemAll(recommendations, StressSleepFormula);
        recommendations = removeItemAll(recommendations, Multi50Plus);
        recommendations = removeItemAll(recommendations, MultiMen);
        recommendations = removeItemAll(recommendations, MultiWomen);
        recommendations = removeItemAll(recommendations, ConceiveWellGold);
        recommendations = removeItemAll(recommendations, ConceiveWellMen);
        recommendations = removeItemAll(recommendations, StJohnsWort);
        recommendations = removeItemAll(recommendations, VeganFishOil);
        recommendations = removeItemAll(recommendations, FishOilMini);
        recommendations = removeItemAll(recommendations, TranquilNight);
        recommendations = removeItemAll(recommendations, DailyMag);
        recommendations = removeItemAll(recommendations, SustainedRelease);
        recommendations = removeItemAll(recommendations, FolatePremiumIronVegan);
        recommendations = removeItemAll(recommendations, SuperMag);
    }
    // console.table(recommendations);
    if (recommendations.length === 0) {
        recommendations.push(DailyImmuneAction);
    }
    recommendations = removeDuplicatesRecommendations(recommendations, (pr) => pr.id);
    let copy = recommendations.sort(compareRanking);
    if (copy.length > 0) {
        return copy.slice(0, 3);
    }
    return copy;
};
function removeDuplicatesRecommendations(products, key) {
    let retVal = [...new Map(products.map((x) => [key(x), x])).values()];
    // console.log(products, retVal);
    return retVal;
}
function compareRanking(a, b) {
    const aRanked = getRankingForSection(a.applicableSection);
    const bRanked = getRankingForSection(b.applicableSection);
    // console.log(aRanked, bRanked);
    if (aRanked < bRanked) {
        return -1;
    }
    if (aRanked > bRanked) {
        return 1;
    }
    // a must be equal to b
    return 0;
}
function getRankingForSection(section) {
    switch (section) {
        case Section.Everyday:
            return SectionAnswerRanking.everyday;
        case Section.Fish:
            return SectionAnswerRanking.fish;
        case Section.Immunity:
            return SectionAnswerRanking.immunity;
        case Section.Move:
            return SectionAnswerRanking.move;
        case Section.Pets:
            return SectionAnswerRanking.pets;
        case Section.Pregnancy:
            return SectionAnswerRanking.pregnancy;
        case Section.Sleep:
            return SectionAnswerRanking.sleep;
        case Section.Stress:
            return SectionAnswerRanking.stress;
        default:
            return 0;
    }
}
function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
        if (arr[i] === value) {
            arr.splice(i, 1);
        }
        else {
            ++i;
        }
    }
    return arr;
}
