<script lang="ts">
  import { onMount } from "svelte";
  import { inview } from "svelte-inview";

  export let posterImageUrl;
  export let alt = "";

  let windowY: number;
  let isInView;
  let userScrollDirection;
  const scaleIncrement = 0.002;
  $: scaleFactor = incrementScalefactor(windowY);
  $: lastY = 0;

  onMount(() => {});

  function incrementScalefactor(y) {
    if (isInView) {
      let factor =
        lastY > windowY
          ? checkIncrement(scaleFactor - scaleIncrement)
          : scaleFactor + scaleIncrement;

      lastY = windowY;
      return factor;
    } else {
      return 1;
    }
  }

  function checkIncrement(i) {
    return (i < 1)  ? 1 : i;
  }

</script>

<svelte:window bind:scrollY={windowY} />

<div class="flex flex-col max-w-xl w-full absolute bottom-24 left-0">
  <!-- class="max-w-xl w-full absolute bottom-24 left-0" -->

  <div class="max-w-2xl w-full overflow-hidden">
    <!-- TODO: 261 wide , 364px high static mask -->
    <img
    src={posterImageUrl}
    {alt}
    style="--image-scale-factor:{scaleFactor};"
    class="scaled bg-transparent w-full h-full"
    use:inview={{}}
    on:change={(event) => {
      const { inView, entry, scrollDirection, observer, node } = event.detail;
      isInView = inView;
      userScrollDirection = scrollDirection;
      //   console.log(userScrollDirection);
    }}
    />
  </div>
  
</div>

<style>
  .scaled {
    transform: scale(
      var(--image-scale-factor, 1)
    ); /* Equal to scaleX(0.7) scaleY(0.7) */
  }
</style>
