<script>
    import "@lottiefiles/lottie-player";
    export let src;

</script>

<lottie-player
  autoplay
  loop
  mode="normal"
  webworkers
  {src}
>
</lottie-player>