<script>
  import StopMotionSection from "../subsections/StopMotionSection.svelte";
  import { questions } from "../../stores/questions.store";
  import { Section } from "../../interfaces/interfaces";
  let y;
</script>

<svelte:window bind:scrollY={y} />

<StopMotionSection
  question={$questions.everyday}
  {y}
  sectionName={Section.Everyday}
/>
