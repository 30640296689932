<script lang="ts">
  import type { ProductRecommendation } from "../../interfaces/interfaces";
 

  export let recommendation: ProductRecommendation;

</script>

<div
  class="flex flex-col rounded-lg flex-wrap justify-between items-center text-bm-recommendation-teal bg-white  p-6 w-full py-6 m-4 grow max-w-[254px] {$$props.class}"
>
  <p class="text-bm-recommendation-teal font-bold font-dmsans text-base flex flex-col justify-center items-center self-center my-4 h-14"
  draggable="false">
    {recommendation.text}
  </p>

  {#if recommendation.imageUrl}
    <img
      src="images/products/{recommendation.imageUrl}"
      alt={recommendation.text}
      class="h-40"
      draggable="false"
    />
  {/if}

  {#if recommendation.claim}
    <p class="text-bm-recommendation-teal text-xs my-4 font-dmsans">{recommendation.claim}</p>
  {/if}

  <a
    class="text-white bg-bm-recommendation-teal rounded-full font-bold font-dmsans my-4 text-sm h-7 w-36 flex flex-col justify-center items-center"
    draggable="false"
    href={recommendation?.externalUrl ?? '#'}
    target="_blank"
    >View product</a>
</div>
