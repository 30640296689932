<script lang="ts">
  import { createForm } from "felte";
  import { profile } from "../stores/questions.store";
  import { scrollRef } from "svelte-scrolling";
  import { processUserFormData } from "../lib/marketo";
  import { Section } from "../interfaces/interfaces";
  import { Recaptcha, recaptcha, observer } from "svelte-recaptcha-v2";

  $: isLoadingSpinnerVisible = false;
  $: lastValues = {} as any;
  const googleRecaptchaSiteKey = "6LebohogAAAAAPlo1YhM4YD-RNUH-2lWQ2WQRDk5";

  const onCaptchaReady = (event) => {
    console.log("recaptcha init has completed.");
    /*
      │You can enable your form button here.
      */
  };

  const onCaptchaSuccess = async (event) => {
    /*
      │If using checkbox method, you can attach your
      │form logic here, or dispatch your custom event.
      */
    lastValues.sectionsUnlocked = [];

    try {
      // let leadIdCreated = await processUserFormData(lastValues);
      console.log("token received and processed: " + event.detail.token);

      // if (leadIdCreated) {
      lastValues.leadId = "";
      $profile = lastValues;
      setTimeout(() => {
        document.getElementById("section-" + Section.Immunity).scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 200);
      // }
    } catch (e) {
      console.log("couldn't process user form data", e);
    }
  };

  const onCaptchaError = (event) => {
    console.log("recaptcha init has failed.", event);
    /*
      │Usually due to incorrect siteKey.
      |Make sure you have the correct siteKey..
      */
  };

  const onCaptchaExpire = (event) => {
    console.log("recaptcha api has expired");
    /*
      │Normally, you wouldn't need to do anything.
      │Recaptcha should reinit itself automatically.
      */
  };

  const onCaptchaOpen = (event) => {
    console.log("google decided to challange the user");
    /*
      │This fires when the puzzle frame pops.
      */
  };

  const onCaptchaClose = (event) => {
    console.log("google decided to challange the user");
    /*
      │This fires when the puzzle frame closes.
      │Usually happens when the user clicks outside
      |the modal frame.
      */
  };
  // https://github.com/basaran/svelte-recaptcha-v2
  const { form, errors } = createForm({
    onSubmit: async (values) => {
      isLoadingSpinnerVisible = true;

      try {
        lastValues = values;
        console.log("launching recaptcha");
        recaptcha.execute();

        console.log("pending for google response");
        const event = await Promise.resolve(observer);

        console.log("tokenised");
        const recaptchaToken = event.detail?.token ? event.detail.token : false;

        if (!recaptchaToken) {
        }
      } catch (e) {
        console.log("Issue found in recaptcha,", e);
      } finally {
        console.log("Ending");

        isLoadingSpinnerVisible = false;
      }
    },
    validate: async (values) => {
      const errors = <any>{};

      if (values.age && values.age === "017") {
        errors.ageerror = "Sorry, you need to be 18+ to continue.";
      } else if (values.age && values.age === "-1") {
        errors.ageerror = "Please select your age.";
      }
      if (values.gender && values.gender === "-1") {
        errors.error =
          "Please complete all mandatory fields marked with * to complete the Good Health Quiz";
      }

      if (!values.name || !values.email || !values.age || !values.consent) {
        errors.error =
          "Please complete all mandatory fields marked with * to complete the Good Health Quiz";
      }

      return errors;
    },
  });
</script>

<svelte:window />

<section
  class="flex flex-col items-center pt-12 bg-bm-red justify-center"
  use:scrollRef={"topform"}
>
  <div class="px-4 sm:px-32 flex flex-col justify-start w-full">
    <form class=" mt-12 w-full px-4 flex flex-col py-6 sm:py-36" use:form>
      <div class="form-fields flex flex-col w-full justify-start mb-8 max-w-md">
        <div class="mb-6">
          <label
            for="name"
            class="block mb-2 text-sm font-medium text-white font-dmsans"
            >Your first name <sup>*</sup></label
          >
          <input
            type="text"
            id="name"
            name="name"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 font-dmsans h-12"
            placeholder="eg. Michael"
            value={$profile.name ?? ""}
            required
          />
        </div>

        <div class="mb-6">
          <label
            for="email"
            class="block mb-2 text-sm font-medium text-white font-dmsans"
            >Your email address <sup>*</sup></label
          >
          <input
            type="email"
            id="email"
            name="email"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 h-12"
            placeholder="eg. Michael.Mosley@gmail.com"
            value={$profile.email ?? ""}
            required
          />
        </div>

        <div class="mb-6">
          <label
            for="age"
            class="block mb-2 text-sm font-medium text-white font-dmsans"
            >Your age <sup>*</sup></label
          >
          <select
            id="age"
            name="age"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  font-dmsans  h-12"
          >
            <option value="-1" selected>Please Select</option>
            <option value="017">&lt; 17</option>
            <option value="1830">18 - 30</option>
            <option value="3149">31 - 49</option>
            <option value="50plus">50 +</option>
          </select>

          {#if $errors.ageerror}
            <div>
              <p class="text-xs mt-4 text-bm-blue">
                {$errors.ageerror}
              </p>
            </div>
          {/if}
        </div>

        <div class="mb-6">
          <label
            for="gender"
            class="block mb-2 text-sm font-medium text-white font-dmsans"
            >Gender <sup>*</sup></label
          >
          <select
            id="gender"
            name="gender"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  font-dmsans  h-12"
          >
            <option value="-1" selected>Please Select</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
            <option value="nonbinary">Non-Binary</option>
            <option value="other">Other</option>
            <option value="none">Rather not say</option>
            <option value="transgender">Transgender</option>
          </select>
        </div>
        <p class="text-white text-xs font-dmsans">* Required Fields</p>

        <div class="flex flex-col items-start mb-4">
          <div class="text-sm flex flex-row  my-6 justify-start">
            <input
              id="consent"
              name="consent"
              aria-describedby="consent"
              type="checkbox"
              class="w-4 h-4 bg-gray-50 rounded  font-dmsans"
              required
            />
            <label
              for="consent"
              class="font-medium text-white dark:text-gray-300 ml-4 font-dmsans"
              >I agree to becoming a Blackmores member and agree to the <a
                href="https://www.blackmores.com.au/privacy"
                class="underline"
                target="_blank">Privacy Policy</a
              >
              <sup>*</sup>
            </label>
          </div>

          <div class="text-bm-blue text-xs mb-6">
            {#if $errors.error}
              {#each $errors.error as anError}
                <p>{anError}</p>
              {/each}
            {/if}
          </div>
          <div class="w-full">
            <div class="mb-4">
              <button
                disabled={isLoadingSpinnerVisible}
                type="submit"
                class=" bg-white px-12 py-4 rounded-full text-bm-orange font-bold text-lg font-dmsans w-full sm:w-fit inline-flex justify-center items-center"
              >
                {#if isLoadingSpinnerVisible}
                  <svg
                    role="status"
                    class="inline mr-2 w-4 h-4 text-gray-200 animate-spin fill-bm-orange"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                {/if}

                Submit Details
              </button>

              <!-- <button type="submit" class="bg-white px-12 py-4 rounded-full text-bm-orange font-bold text-lg font-dmsans w-full sm:w-fit">Submit Details</button> -->
            </div>
          </div>
        </div>
        <Recaptcha
          sitekey={googleRecaptchaSiteKey}
          badge="inline"
          size="invisible"
          on:success={onCaptchaSuccess}
          on:error={onCaptchaError}
          on:expired={onCaptchaExpire}
          on:close={onCaptchaClose}
          on:ready={onCaptchaReady}
        />
      </div>
    </form>
  </div>
</section>
