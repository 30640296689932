<script lang="ts">
  import { Splide, SplideSlide } from "@splidejs/svelte-splide";
  import "@splidejs/splide/dist/css/splide.min.css";
import MediaQuery from "../MediaQuery.svelte";

  export let items = [];

  export let mobileOptions = {
        arrows: false,
        rewind: true,
        autoplay: true,
        pagination: false,
        speed: 0,
        drag: false,
        interval: 400,
        padding: 0,
        preloadPages: items.length || 1,
        perPage: 1,
        autoWidth:true,
        // clones: 0,
        // cover: true,
        // fixedHeight: 600,
        // heightRatio: 0.5,
        
        // waitForTransition: true,
        trimSpace: true,
        gap: 0,
        type: 'fade'
        // lazyLoad: 'nearby'
      };
    export let desktopOptions = {
        arrows: false,
        rewind: true,
        autoplay: true,
        pagination: false,
        speed: 0,
        drag: false,
        interval: 400,
        padding: 0,
        preloadPages: items.length || 1,
        perPage: 1,
        autoWidth:true,
        clones: 0,
        cover: true,
        fixedHeight: 600,
        heightRatio: 0.5,
        
        // waitForTransition: true,
        trimSpace: true,
        gap: 0,
        type: 'fade'
        // lazyLoad: 'nearby'
      };
</script>
<svelte:head>
    {#each items as image}
      <link rel="preload" as="image" href={image} />
    {/each}
</svelte:head>

<div class="max-w-3xl w-full absolute right-0 z-0">
  <MediaQuery  query="(max-width: 640px)" let:matches>
    
    {#if matches}
    <Splide
      options={mobileOptions}
    >
      {#each items as item, index}
        <SplideSlide  classes="flex flex-col" src={item}>
          <img src={item} alt="stopmo {index}" class="self-end"/>
        </SplideSlide>
      {/each}
    </Splide>
    {/if}
  </MediaQuery>
  <MediaQuery  query="(min-width: 640px)" let:matches>
    {#if matches}
    <Splide
      options={desktopOptions}
    >
      {#each items as item, index}
        <SplideSlide  classes="flex flex-col" src={item}>
          <img src={item} alt="stopmo {index}" class="self-end"/>
        </SplideSlide>
      {/each}
    </Splide>
    {/if}
  </MediaQuery>
</div>

<style  lang="postcss" global>
  .splide__list {
    align-items: flex-end;
  }

  .splide__slide {
    width: 100% !important;
    align-self: flex-end;
    background-size: contain;
  }

  .splide__slide img{
    align-self: flex-end;

  }
</style>