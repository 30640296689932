import { QuestionBackgroundColor, QuestionDirection, TranslationXSpeed, } from "../interfaces/interfaces";
import "./products/recommendations.ds";
export const question = {
    text: "How would you describe your everyday health?",
    blurb: "Your skin works hard. Not only is it the largest organ in the body, but it regulates your temperature and defends against disease and infection.",
    questionColor: QuestionBackgroundColor.Blue,
    lottieUrl: "animations/everyday-large.json",
    lottieMobileUrl: "animations/everyday.json",
    posterImageUrl: "images/everyday.png",
    options: [
        {
            text: "Great, I feel fit and healthy all of the time",
            score: 1,
            id: 1,
        },
        {
            text: "Good, I normally feel fit and healthy",
            score: 1,
            id: 2,
        },
        {
            text: "I feel out of balance with my health",
            score: 8,
            id: 4,
        },
        {
            text: "Average, I could do more to look after my health",
            score: 4,
            id: 3,
        },
    ],
    titles: [
        {
            text: "Everyday",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "Everyday",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Everyday",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "Everyday",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Everyday",
            translationSpeed: TranslationXSpeed.Half,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "Everyday",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Everyday",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.RightToLeft,
        },
    ],
    info: [
        {
            text: "Did",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "You",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Know",
            translationSpeed: TranslationXSpeed.Half,
            direction: QuestionDirection.RightToLeft,
        },
    ],
};
