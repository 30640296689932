import { QuestionBackgroundColor, QuestionDirection, TranslationXSpeed, } from "../interfaces/interfaces";
export const question = {
    text: "How often do you get sick?",
    blurb: "While most people think that oranges contain the most vitamin C, it is in fact the mighty red capsicum that packs the most punch.",
    questionColor: QuestionBackgroundColor.Orange,
    lottieUrl: "animations/immunity-large.json",
    lottieMobileUrl: "animations/immunity.json",
    stopMoUrls: [
        "images/stopmo/immunity-1.jpg",
        "images/stopmo/immunity-3.jpg",
        "images/stopmo/immunity-5.jpg",
        "images/stopmo/immunity-7.jpg",
        "images/stopmo/immunity-9.jpg",
        "images/stopmo/immunity-11.jpg",
        "images/stopmo/immunity-13.jpg",
        "images/stopmo/immunity-15.jpg",
    ],
    options: [
        {
            text: "I haven't been sick in years",
            score: 1,
            id: 9,
        },
        {
            text: "Maybe once a year",
            score: 1,
            id: 10,
        },
        {
            text: "Maybe twice a year",
            score: 4,
            id: 11,
        },
        {
            text: "Every few months or so",
            score: 8,
            id: 12,
        },
    ],
    titles: [
        {
            text: "Immune Health",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Immune Health",
            translationSpeed: TranslationXSpeed.Half,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "Immune Health",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Immune Health",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "Immune Health",
            translationSpeed: TranslationXSpeed.Half,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Immune Health",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "Immune Health",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.LeftToRight,
        },
    ],
    info: [
        {
            text: "Did",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "You",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Know",
            translationSpeed: TranslationXSpeed.Half,
            direction: QuestionDirection.RightToLeft,
        },
    ],
};
