import { QuestionBackgroundColor, QuestionDirection, TranslationXSpeed, } from "../interfaces/interfaces";
export const question = {
    text: "Do you eat fish at least 2-3 times per week?",
    blurb: "Scientific evidence supports fish being the best dietary source of omega-3s and found higher fish intake supports cardiovascular health",
    questionColor: QuestionBackgroundColor.Green,
    lottieUrl: "animations/wellbeing-large.json",
    lottieMobileUrl: "animations/wellbeing.json",
    posterImageUrl: "images/wellbeing.png",
    options: [
        {
            text: "I have a vegan / vegetarian diet",
            score: 8,
            id: 5,
        },
        {
            text: "I don't eat fish",
            score: 8,
            id: 6,
        },
        {
            text: "I like fish, but don't eat it that often",
            score: 4,
            id: 7,
        },
        {
            text: "Fish is my favourite dish and I eat it at least 2-3 times a week",
            score: 1,
            id: 8,
        },
    ],
    titles: [
        {
            text: "WELLBEING",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "WELLBEING",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "WELLBEING",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "WELLBEING",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "WELLBEING",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "WELLBEING",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "WELLBEING",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.RightToLeft,
        },
    ],
    info: [
        {
            text: "Did",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "You",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Know",
            translationSpeed: TranslationXSpeed.Half,
            direction: QuestionDirection.RightToLeft,
        },
    ],
};
