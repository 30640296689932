<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { scrollRef } from "svelte-scrolling";
  import type { Answers } from "../interfaces/interfaces";
  import {
    getRecommendableProducts,
    getRecommendationBlurb,
    scoringScaleFactor,
  } from "../interfaces/lookups";
  import { answers, profile, scores } from "../stores/questions.store";
  import ProductCard from "./carousel/ProductCard.svelte";

  $: products = [];
  $: recommendationBlurb = "";
  $: overallScore =
    100 - Object.values($scores).reduce((a, b) => a + b) * scoringScaleFactor;

  const qUnsubscribe = scores.subscribe((val) => {
    overallScore =
      100 - Object.values(val).reduce((a, b) => a + b) * scoringScaleFactor;
  });
  const unsubscribe = answers.subscribe((val: Answers) => {
    _productsToShow();
    _blurbToShow();
    // console.table(val)
    // console.table(products.map((item) => item.text))
  });

  function _productsToShow() {
    products = getRecommendableProducts() ?? []; // _products;
  }

  function _blurbToShow() {
    recommendationBlurb = getRecommendationBlurb(products) ?? "";
    // console.log("Blurb:", recommendationBlurb);
  }

  onMount(() => {
    _productsToShow();
    _blurbToShow();
  });
  onDestroy(() => {
    unsubscribe();
    qUnsubscribe();
  });
</script>

<section
  class="flex flex-col justify-center items-center bg-bm-green px-4  py-24"
  use:scrollRef={"recommendations"}
  id="section-recommendations"
>
  <div
    class="sm:max-w-4xl flex flex-col w-full justify-center items-center text-center font-dmsans"
  >
    <h3 class="text-white my-2 font-dmsans text-xl font-bold">
      Thanks {$profile.name}
    </h3>
    <h4 class="text-white my-2 font-dmsans font-medium text-2xl">
      Your Good Health score is
    </h4>

    <p
      class="text-9xl text-bm-orange font-dmsans my-16 thin bg-white rounded-full p-8 py-12"
    >
      {overallScore}
    </p>

    {#if products.length > 0}
      <div class="text-white max-w-xl text-lg font-dmsans font-medium mb-8">
        <h3 class="my-4 font-bold text-xl">What does this mean?</h3>
        <p class="my-6">
          Your good health score number is out of 100 that provides you with a
          starting point on your journey to good health.
        </p>
        <p class="my-6">
          {recommendationBlurb}
        </p>

        <p class="my-6">
          If you have any concerns regarding your health, please speak to your
          health professional.
        </p>
        <p class="my-6">
          In addition, if you need more help to support your general health and
          wellbeing you may like to consider <u><b>one</b></u> of the below products
          to assist.
        </p>
      </div>
      <div class="flex flex-wrap justify-center items-center w-full">
        {#each products as product}
          <ProductCard
            recommendation={product}
            class="h-full flex-1 self-stretch"
          />
        {/each}
      </div>
    {:else}
      <p class="text-white my-8">
        By filling in the quiz we can help recommendation products that might
        better your overall health.
      </p>
    {/if}

    <!-- <p class="text-white  my-4  text-xl">Good health changes everything</p> -->
    <div class="text-white my-4  text-lg">
      Always read the label and follow the directions for use.
    </div>

    <button
      class="text-bm-teal bg-white rounded-full font-bold font-dmsans my-8 text-sm h-12 w-36 flex flex-col justify-center items-center"
      draggable="false"
      on:click={() => {
        profile.update((val) => {
          return { ...val };
        });
        const tmpProfile = $profile;
        tmpProfile.sectionsUnlocked = [];
        profile.set(tmpProfile);
        window.location.href = "/";
      }}
      target="_blank">Start Over</button
    >
  </div>
</section>
