import { QuestionBackgroundColor, QuestionDirection, TranslationXSpeed, } from "../interfaces/interfaces";
export const question = {
    text: "What kind of fur babies do you have?",
    blurb: "Pets, especially dogs and cats, can reduce stress, anxiety, and depression, ease loneliness, encourage exercise and playfulness, and even improve your cardiovascular health.",
    questionColor: QuestionBackgroundColor.Teal,
    lottieUrl: "animations/pets-large.json",
    lottieMobileUrl: "animations/pets.json",
    stopMoUrls: [
        "images/stopmo/pets-1.jpg",
        "images/stopmo/pets-2.jpg",
        "images/stopmo/pets-3.jpg",
        "images/stopmo/pets-4.jpg",
        "images/stopmo/pets-5.jpg",
    ],
    options: [
        {
            text: "We are a dog family",
            score: 0,
            id: 17,
        },
        {
            text: "We are a cat family",
            score: 0,
            id: 18,
        },
        {
            text: "We have at least one of each in our family",
            score: 0,
            id: 19,
        },
        {
            text: "I don't have any fur babies",
            score: 4,
            id: 20,
        },
    ],
    titles: [
        {
            text: "PETS",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "DOG",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "Cat",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Pets",
            translationSpeed: TranslationXSpeed.Normal,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "ZOOMIES",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "PETS",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "PETS",
            translationSpeed: TranslationXSpeed.Half,
            direction: QuestionDirection.RightToLeft,
        },
    ],
    info: [
        {
            text: "Did",
            translationSpeed: TranslationXSpeed.Triple,
            direction: QuestionDirection.RightToLeft,
        },
        {
            text: "You",
            translationSpeed: TranslationXSpeed.Double,
            direction: QuestionDirection.LeftToRight,
        },
        {
            text: "Know",
            translationSpeed: TranslationXSpeed.Half,
            direction: QuestionDirection.RightToLeft,
        },
    ],
};
